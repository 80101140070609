import {useRouter} from "next/router";
import {getSlugs} from "@qoohoo/common/utils/url";
import routes from "routes";

const withActiveTab = (TabComponent) => {
  return (props) => {
    const router = useRouter();

    function checkIsActiveTab(route) {
      try {
        // Handling case for home page
        if (route === routes.home.route) {
          return router.asPath === route;
        }
        
        const isActive = router.asPath.indexOf(route) === 0;

        return isActive;
      } catch(e) {
        console.log('err', e);
      }
    }

    const isActive = checkIsActiveTab(props.tab.route);

    return (
      <TabComponent
        {...props}
        isActive={isActive}
      />
    )
  };
}

export default withActiveTab;