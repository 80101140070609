import cls from "classnames";
import UserOnboardingBanner from "./UserOnboardingBanner";
import BackButton from "./BackButton";

export default function MainContent({
  children,
  isFullLayout,
  showUserOnboarding,
}) {
  return (
    <div className={cls("flex w-full flex-1", !isFullLayout && "ml-56 mt-[60px]")}>
      {isFullLayout ? (
        <>
          {<BackButton/>}
          {showUserOnboarding && <UserOnboardingBanner />}
          {children}
        </>
      ) : (
        <div className="flex flex-col w-full bg-white rounded p-6 xl:pr-36">
          {<BackButton/>}
          {showUserOnboarding && <UserOnboardingBanner />}
          {children}
        </div>
      )}
    </div>
  );
}
