import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { QOOHOO_WEBSITE_DOMAIN } from "constants/env";
import { noAuthRoutes } from "constants/routes";
import httpf from "utils/api/frontend/httpf";
import useSessionStore from "store/sessionStore";
import useStore from "store/store";
import routes from "routes";

import NoSSR from "@qoohoo/common/components/NoSSR";
import DashboardLayout from "components/layout/DashboardLayout";
import Spinner from "@qoohoo/common/library/loader/Spinner";
import getAuthMe from "helpers/getAuthMe";

export default function AuthenticationProvider({ children }) {
  const setUserData = useSessionStore((state) => state.setUserData);
  const [isLoading, setIsLoading] = useState(true);

  const router = useRouter();

  const _Wrapper = useMemo(() => {
    if (noAuthRoutes.indexOf(router.pathname) > -1) {
      return Fragment;
    } else {
      return DashboardLayout;
    }
  }, [router.pathname]);

  const checkRouteAuth = useCallback((user, setIsLoading) => {
    const isUserAuthenticated = !!user && user?.isCreator;
    const currentRoute = router.pathname;

    const isAuthRoute = noAuthRoutes.indexOf(currentRoute) == -1;

    let redirectPath = null;

    if (isUserAuthenticated) {
      if (currentRoute === routes.login.route) {
        redirectPath = routes.home.route;
      } else if (currentRoute === routes.onboarding.route) {
        if (user.isCreator) {
          redirectPath = routes.home.route;
        }
      }
    } else {
      if (isAuthRoute) {
        redirectPath = routes.login.route;
      }
    }

    if (redirectPath) {
      router.push(redirectPath).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const fetchUser = useCallback(() => {
    setIsLoading(true);

    let user = null;

    getAuthMe().then((res) => {
        setUserData({ ...res.data });

        user = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        checkRouteAuth(user, setIsLoading);
      });
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <NoSSR>
      {isLoading ? (
        <div className={"flex items-center justify-center h-screen"}>
          <Spinner color="#16191B" className="h-12 w-12" />
        </div>
      ) : (
        <_Wrapper>{children}</_Wrapper>
      )}
    </NoSSR>
  );
}
