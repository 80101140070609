import {
  HomeFillIcon,
  HomeOutlineIcon,
  OfferingsFillIcon,
  OfferingsOutlineIcon,
  ProfileFillIcon,
  ProfileOutlineIcon,
  SettingsFillIcon,
  SettingsOutlineIcon,
  TransactionFillIcon,
  TransactionOutlineIcon,
} from "components/layout/Icons";

const routes = {
  onboarding: {
    label: 'Onboarding',
    route: '/creator-onboarding'
  },
  home: {
    label: 'Home',
    icon: HomeOutlineIcon,
    selected_icon: HomeFillIcon,
    route: '/'
  },
  superlink: {
    label: 'Superlink',
    icon: '/assets/icons/superlink.svg',
    route: '/superlink'
  },
  offerings: {
    label: 'My Offerings',
    icon: OfferingsOutlineIcon,
    selected_icon: OfferingsFillIcon,
    route: '/offerings',
  },
  create_offerings: {
    label: "Create Telegram Payment",
    route: '/offerings/create',
  },
  edit_offerings: {
    label: "Edit Telegram Payment",
    route: '/offerings/edit',
  },
  offering_members: {
    label: "Members",
    route: '/offerings/members',
    goBack: () => goBackButtonConfig.offerings
  },
  offering_settings: {
    label: "Settings",
    route: '/offerings/settings',
    goBack: () => goBackButtonConfig.offerings
  },
  /*
    wallOfLove: {
      label: 'Wall of Love',
      icon: '/assets/icons/wall-of-love.svg',
      route: '/wall-of-love'
    },
  */
  settings: {
    label: 'Settings',
    icon: SettingsOutlineIcon,
    selected_icon: SettingsFillIcon,
    route: '/settings'
  },
  settings_payments: {
    label: 'Payments',
    route: '/settings/payments'
  },
  profile: {
    label: 'My Profile',
    icon: ProfileOutlineIcon,
    selected_icon: ProfileFillIcon,
    route: '/profile'
  },
  transactions: {
    label: 'Transactions',
    icon: TransactionOutlineIcon,
    selected_icon: TransactionFillIcon,
    route: '/transactions'
  },
  become_a_creator: {
    label: 'Become a creator',
    route: '/become-a-creator'
  },
  login: {
    route: '/login'
  },
  /*
    allProducts: {
      label: 'All products',
      icon: '',
      route: '/all-products'
    },
  */
}

export const routesWithFullLayout = [
  routes.superlink,
];

export function getMatchRoute(router) {
  let matchRoute = false;
  const path = router.pathname;

  // TODO: Improve logic once the routes object has '/:id' in it rather than '/'
  const pathWithoutId = path.replace("/[id]", "");

  const routeKey = Object.keys(routes).find((key) => {
    const thisRoute = routes[key];

    return thisRoute.route === pathWithoutId;
  });

  if (routeKey) {
    matchRoute = routes[routeKey];
  }

  return matchRoute;
}

const goBackButtonConfig = {
  offerings: {
    label: "All offerings",
    route: routes.offerings,
  },
};

export default routes;