import { useCallback } from "react";
import Link from "next/link";
import Router from "next/router";
import toast from "react-hot-toast";
import cls from "classnames";

import { addTagsInRecording } from "@qoohoo/common/utils/analytics/clarity";

import Spinner from "@qoohoo/common/library/loader/Spinner";
import routes from "routes";

import useSessionStore from "store/sessionStore";
import useHasHydrated from "@qoohoo/common/hooks/useHasHydrated";
import { QOOHOO_WEBSITE_DOMAIN } from "constants/env";

import AuthDropdownButton from "components/auth/AuthDropdownButton";
import axios from "axios";
import Tag from "./Tag";


export default function NavBar({
  sticky = false,
  useDropdownAuth = false,
}) {
  const userData = useSessionStore((state) => state.userData);
  const setUserData = useSessionStore((state) => state.setUserData);
  const current_screen = "nav";

  const hasUserData = !!userData?.uid;

  const isBrowser = useHasHydrated();

  function handleLogout() {
    axios.get(`${QOOHOO_WEBSITE_DOMAIN}/api/auth/logout`, {
      withCredentials: true,
    }).finally(() => {
      setUserData({});

      addTagsInRecording({ isUserLoggedOut: true });
      toast.success("Logged out successfully");
      Router.reload();
    });
  }

  function renderAuthButton() {
    let authButton = null;

    if (!hasUserData) {
      authButton = <Spinner color="#16191B66" className="!h-8 !w-8"/>;
    } else if (isBrowser) {
      if (hasUserData) {
        authButton = (
          <AuthDropdownButton
            handleLogout={handleLogout}
            isLightMode
          />
        );
      } else {
        authButton = null;
      }
    }

    return authButton;
  }

  return (
    <div className="fixed top-0 w-full z-[1]">
      <header
        className={cls(
          "font-nunito z-[99] border-b border-b-col-[#F2F3F5]",
          sticky ? "sticky top-0 right-0" : "relative",
        )}
      >
        <nav
          className={cls(
            "flex h-[60px] items-stretch bg-white items-center justify-between text-[#16191B]",
          )}
        >
          <div className="flex w-56 bg-[#F9FAFA] px-5 items-center">
            <Link
              href={routes.home.route}
            >
              <img
                src="/assets/images/qoohoo-logo-black.svg"
                alt="qoohoo Logo"
                className="h-7 select-none"
              />
            </Link>

            <Tag className="ml-2" color={Tag.COLORS.red}>
              Beta
            </Tag>
          </div>

          <div className="flex px-5">
            <ul
              className={cls(
                "items-center font-lg",
                useDropdownAuth ? "flex" : "hidden md:flex"
              )}
            >
              {useDropdownAuth && renderAuthButton()}
            </ul>
            {/* {!loading && showHamburgerMenu && (
            <HamMenu
              isLightMode={isLightMode}
              handleLogout={handleLogout}
              current_screen={current_screen}
              showAuth={showAuth}
              isSuperlinkView={isSuperlinkView}
              featureLinks={links}
            />
          )} */}
          </div>
        </nav>
      </header>
    </div>
  );
}
