import create from "zustand";
import { devtools } from "zustand/middleware";

const sessionStore = (set) => ({
  userData: {},
  username: "",
  setUserData: (userData) => {
    window.userData = userData;
    set({ userData: userData });
  },
  setUsername: (username) => {
    set({ username: username });
  },
});

const useSessionStore = create(devtools(sessionStore));

export default useSessionStore;
