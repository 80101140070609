export const IS_PROD = process.env.USER_ENV === "production";
export const IS_LOCAL = process.env.USER_ENV === "development";

export const API_BASE = process.env.API_BASE;
export const WEBSITE_DOMAIN = process.env.WEBSITE_DOMAIN;
export const QOOHOO_WEBSITE_DOMAIN = process.env.QOOHOO_WEBSITE_DOMAIN;
export const SUPERLINK_DOMAIN = process.env.SUPERLINK_DOMAIN;
export const DASHBOARD_DOMAIN = process.env.DASHBOARD_DOMAIN;
export const CDN_DOMAIN = process.env.CDN_DOMAIN;
export const STRIPE_OAUTH_URL = process.env.STRIPE_OAUTH_URL;
export const NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY;

export const isBrowser = typeof document !== "undefined";
