import axios from "axios";
import useSessionStore from "store/sessionStore";
import store from "store/store";
import routes from "routes";
import Router from "next/router";
import { DASHBOARD_DOMAIN } from "constants/env";
import {noAuthRoutes} from "../../../constants/routes";

const httpf = axios.create({
  withCredentials: true
});

httpf.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        useSessionStore.getState().setUserData({});
        // TODO: Open Login Modal on 401
        // store.getState().LoginModalController().openModal();
        
        const currentPath = Router.router.state.pathname;

        const isAuthRoute = noAuthRoutes.indexOf(currentPath) === -1;

        if(isAuthRoute) {
          const queryParams = new URLSearchParams();
          const fullPath = Router.router.state.asPath;
         
          const hasRedirectPathAfterLogin = (fullPath !== '/'); // Note: Not considering '/' path as a redirect
          let redirectRoute = `${DASHBOARD_DOMAIN}/${routes.login.route}`;
          
          if(hasRedirectPathAfterLogin) {
            queryParams.set("next", fullPath);
            const queryString = decodeURIComponent(queryParams.toString());

            redirectRoute+=`?${queryString}`
          }

          window.location.href = redirectRoute;        
        }

        return Promise.reject(err);
      }

      if (err?.response?.status === 400) {
        const e = new Error(err?.response?.data?.message);
        e.name = err?.response?.data?.type;
        e.response = err.response;

        throw e;
      }
    }

    return Promise.reject(err);
  }
);

export default httpf;
