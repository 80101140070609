import cls from "classnames";

export default function Tag({children, className, color}) {

  return (
    <div
      className={cls(
        "rounded text-xs px-2 py-0.5 font-bold",
        className
      )}
      style={{
        color: color.text,
        backgroundColor: color.background
      }}
    >
      {children}
    </div>
  );
}

Tag.COLORS = {
  red: {
    text: "#C81616",
    background: "#FBE3CE"
  }
};