import { useEffect } from "react";
import { useRouter } from "next/router";

import { QOOHOO_WEBSITE_DOMAIN } from "constants/env";
import httpf from "utils/api/frontend/httpf";
import useSessionStore from "store/sessionStore";
import useUserOnboardingStore from "../userOnboardingStore";
import routes from "routes";

export default function withUserOnboarding() {
  const [setData, setIsLoading] = useUserOnboardingStore(
    (state) => [state.setData, state.setIsLoading]
  );
  const userData = useSessionStore((state) => state.userData);

  const router = useRouter();

  const { isCreator } = userData;

  function fetchOnboardingData() {
    setIsLoading(true);

    httpf
      .get(`${QOOHOO_WEBSITE_DOMAIN}/api/get-onboarding-details`)
      .then((res) => {
        setData({ ...res.data });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (isCreator) fetchOnboardingData();
  }, [isCreator]);
}
