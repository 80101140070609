import Link from "next/link";
import { useRouter } from "next/router";
import { getMatchRoute } from "routes";

export default function BackButton() {
  function getShowBackButton() {
    const router = useRouter();

    const matchRoute = getMatchRoute(router);
    const goBack = matchRoute?.goBack?.call();

    return goBack;
  }

  const backButton = getShowBackButton();

  if (!!backButton) {
    return (
      <_BackButton label={backButton.label} link={backButton.route.route} />
    );
  }

  return null;
}

function _BackButton({ label, link }) {
  return (
    <Link
      href={link}
      className="flex flex-row items-center justify-start gap-2 mb-4 w-fit hover:underline"
    >
      <img src="/assets/icons/back.svg" alt="back" className="w-4" />
      <span className="text-sm text-[#898989] font-semibold">{label}</span>
    </Link>
  );
}
