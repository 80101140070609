import cls from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import routes from "routes";
import useSessionStore from "store/sessionStore";
import {
  getUserOnboardingSteps,
  userOnboardingStepsStatus,
} from "store/userOnboardingStore/helpers/userOnboarding";

export default function UserOnboardingBanner() {
  const router = useRouter();
  const steps = getUserOnboardingSteps();
  const userData = useSessionStore((state) => state.userData);

  const showBanner = !userData.is_creator_onboarding_complete && steps;

  const showProfileBanner =
    showBanner &&
    steps.find((step) => step.id === "user_profile").status !==
      userOnboardingStepsStatus.COMPLETED &&
    router.route !== routes.profile.route;

  const showPaymentsBanner =
    showBanner &&
    steps.find((step) => step.id === "setup_payments").status !==
      userOnboardingStepsStatus.COMPLETED &&
    userData.country !== "IN" &&
    router.route !== routes.settings_payments.route;

  const showIndianPaymentsBanner =
    showBanner &&
    steps.find((step) => step.id === "setup_payments").status !==
      userOnboardingStepsStatus.COMPLETED &&
    userData.country === "IN" &&
    router.route !== routes.settings_payments.route;

  const showBannerSection =
    showProfileBanner || showPaymentsBanner || showIndianPaymentsBanner;

  return (
    showBannerSection && (
      <div className="flex flex-col items-center justify-center gap-2 mb-2 w-full">
        {showProfileBanner && (
          <WarningBanner
            title={"Your creator profile is incomplete. Complete it now."}
            link={routes.profile.route}
            linkText={"Complete profile"}
          />
        )}
        {showPaymentsBanner && (
          <WarningBanner
            title={"Setup your payments to start accepting payments."}
            link={routes.settings_payments.route}
            linkText={"Setup payments"}
          />
        )}
        {showIndianPaymentsBanner && (
          <WarningBanner
            title={
              "Setup your payments to enable automatic payouts of your earnings."
            }
            link={routes.settings_payments.route}
            linkText={"Setup payments"}
          />
        )}
      </div>
    )
  );
}

function WarningBanner({ title, link, linkText }) {
  return (
    <div className="bg-[#FFF8E8] border-[#F0E6CE] rounded-lg p-2 border-[1px] flex flex-row items-center justify-center w-full">
      <img src="/assets/icons/warning.svg" alt="warning" className="w-9" />
      <span className="text-sm text-[#16191B] ml-4 mr-auto font-semibold">
        {title}
      </span>
      <Link href={link}>
        <span className="text-sm text-[#384AF1] font-semibold px-4 py-2 underline text-center">
          {linkText}
        </span>
      </Link>
    </div>
  );
}
