export function isBasicCreator(userData) {
    const { creator_categories, name } = userData;
  
    const basicCreatorOnboardingCompleted = creator_categories.length > 0 && name;
  
    return !!basicCreatorOnboardingCompleted;
  }
  
  export function isSuperlinkCreator(userData) {
    const {
      creator_categories,
      name,
      use_new_profile_page: isSuperlinkEnabled,
    } = userData;
  
    const superlinkOnboardingCompleted =
      creator_categories.length > 0 && name && isSuperlinkEnabled;
  
    return !!superlinkOnboardingCompleted;
  }

export function isFinanceCreator(userData) {
  let _isFinanceCreator;
  
  const { creator_categories } = userData;

  _isFinanceCreator = creator_categories.some((category) => {
    return [category.display_name.toLowerCase(), category.name.toLowerCase()].indexOf("finance") > -1;
  });

  return _isFinanceCreator;
}