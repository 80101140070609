import Head from "next/head";
import React from "react";
import { DefaultSeo } from "next-seo";

import getSEOConfig from '../../next-seo.config';

import AuthenticationProvider from "components/AuthenticationProvider";
import { Toaster } from "react-hot-toast";

import '../styles/globals.css'
import "../styles/tailwind.globals.css";
import "../styles/hover-animation.css";
import "@qoohoo/common/styles/Uploader.css";
import "@qoohoo/common/styles/mockup.css";
import "@qoohoo/common/styles/animations.css";
import "@qoohoo/common/styles/gradients.css";
import withUserOnboarding from "store/userOnboardingStore/helpers/withUserOnboarding";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
  withUserOnboarding();
  const SEOConfig = getSEOConfig();
  
  const router = useRouter();
  
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <DefaultSeo {...SEOConfig} />
      <AuthenticationProvider>
        <Component {...pageProps} />
        <Toaster />
      </AuthenticationProvider>
    </>
  );
}

export default MyApp
