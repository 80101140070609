import Link from "next/link";
import { Transition } from "@headlessui/react";
import cls from "classnames";
import { Fragment, useState } from "react";
import UserAvatar from "@qoohoo/common/components/UserAvatar";
import { isSuperlinkCreator } from "@qoohoo/common/helpers/user";
import { isValidUsername } from "@qoohoo/common/utils/string";
import { ChevronDownIcon, ChevronRightIcon } from "@qoohoo/common/library/Icons";
import useSessionStore from "store/sessionStore";
import {SUPERLINK_DOMAIN} from "../../constants/env";

export default function AuthButton({ handleLogout, isLightMode }) {
  const userData = useSessionStore((state) => state.userData);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userFullName = userData.name?.split(" ")[0] || "My account";
  const username = isValidUsername(userData?.username)
    ? userData?.username
    : "qoohoo_buddy";

  function handleButtonClick() {
    setDropdownOpen((prev) => !prev);
  }

  function handleBlur(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(false);
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Escape") {
      setDropdownOpen(false);
    }
  }

  const dropdownOptions = [
    {
      title: "Your superlink profile",
      href: `${SUPERLINK_DOMAIN}/${userData?.username}`,
      visible: isSuperlinkCreator(userData),
    }
  ];

  return (
    <>
      <div
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        tabIndex="0"
        className="z-10 sm:relative inline-block text-left max-h-8 sm:max-h-[42px] outline-none focus:outline-none"
      >
        <button
          onClick={handleButtonClick}
          className="inline-flex justify-center items-center space-x-2 w-full text-white bg-transparent rounded-full outline-none focus:outline-none"
        >
          <div className="flex items-center justify-center bg-[#1F2429] font-bold w-8 h-8 sm:w-9 sm:h-9 overflow-hidden rounded-full border border-[#1F2429]">
            <UserAvatar
              avatarUrl={userData.avatar}
              userName={userFullName}
              isUserNameSetByUser={!!userData.name}
              alt=""
            />
          </div>
          <span
            className={cls(
              "hidden sm:block",
              isLightMode ? "text-[#16191B]" : "",
              "leading-[42px]"
            )}
          >
          {userFullName}
        </span>
          <ChevronDownIcon
            className={cls(
              "hidden sm:block transition-transform stroke-current",
              dropdownOpen && "rotate-180",
              isLightMode ? "text-[#16191B]" : "text-[#FFFFFF]"
            )}
          />
        </button>
        <Transition
          show={dropdownOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-100"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="absolute z-50 top-16 sm:top-auto right-0 w-full p-3 sm:p-0 sm:max-w-[338px] sm:w-[338px] sm:mt-4 origin-top-right outline-none focus:outline-none children:outline-none focus:children:outline-none">
            <div
              className={cls(
                "z-50 border rounded-lg overflow-hidden outline-none focus:outline-none",
                isLightMode
                  ? "text-[#504E4E] bg-[#FFFFFF]"
                  : "text-[#F0F0F0] bg-[#22272B] border-[#292E33]"
              )}
            >
              <div className="w-full px-4 pt-6 pb-9">
                <div className="flex flex-row items-center">
                  <div className="flex items-center justify-center bg-[#1F2429] font-bold w-12 h-12 overflow-hidden rounded-full text-white">
                    <UserAvatar
                      avatarUrl={userData.avatar}
                      userName={userFullName}
                      isUserNameSetByUser={!!userData.name}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="ml-3 max-w-[243px]">
                    <h1
                      className={cls(
                        "font-semibold leading-[150%] overflow-hidden text-ellipsis",
                        isLightMode ? "text-[#222528]" : "text-[#F0F0F0]"
                      )}
                    >
                      {userData?.name || "qoohoo member"}
                    </h1>
                    <h2
                      className={cls(
                        "mt-1 text-sm leading-[150%] text-ellipsis",
                        isLightMode ? "text-[#504E4E]" : "text-[#B8B8B8]"
                      )}
                    >
                      @{username}
                    </h2>
                  </div>
                </div>
                <div
                  className={cls(
                    "text-sm leading-[150%] mt-6 py-6 border-t",
                    isLightMode ? "border-[#EFF0F1]" : "border-[#292E33]"
                  )}
                >
                  <h1
                    className={
                      isLightMode ? "text-[#504E4E]" : "text-[#F0F0F0]"
                    }
                  >
                    Your details
                  </h1>
                  <div
                    className={cls(
                      "mt-3 pl-3 border-l-[3px] max-w-xs truncate",
                      isLightMode
                        ? "border-[#222528] text-[#222528]"
                        : "border-[#292E33] text-[#B8B8B8]"
                    )}
                  >
                    {userData?.email && <h2>{userData?.email}</h2>}
                    {userData?.mobile ? (
                      <h2 className="mt-2">{userData?.mobile}</h2>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {dropdownOptions.map(
                  (option) =>
                    option.visible && (
                      <Link
                        href={option.href}
                        key={option.href}
                        target="_blank"
                        className="group w-full block cursor-pointer outline-none focus:outline-none hover:opacity-80 mt-5"
                      >
                        <span className="flex justify-between items-center">
                          <span className="text-sm">{option.title}</span>
                          <ChevronRightIcon
                            className={cls(
                              "w-6 h-6 group-hover:animate-rectilinear",
                              isLightMode
                                ? "stroke-[#6C6666]"
                                : "stroke-[#F0F0F0]"
                            )}
                          />
                        </span>
                      </Link>
                    )
                )}
              </div>
              <div
                className={cls(
                  "py-4",
                  isLightMode
                    ? "bg-[#FFFFFF] border-t border-[#D3D5D7]"
                    : "bg-[#272B30]"
                )}
              >
                <button
                  onClick={handleLogout}
                  className={cls(
                    "flex items-center mx-auto px-6 py-2 font-semibold border rounded-full w-max transition-all duration-100",
                    {
                      "text-[#222528] focus:bg-[#222528] hover:bg-[#222528] focus:text-white hover:text-white":
                      isLightMode,
                    },
                    {
                      "text-white focus:bg-white hover:bg-white focus:text-[#222528] hover:text-[#222528]":
                        !isLightMode,
                    }
                  )}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
