import routes from "routes";
import useSessionStore from "store/sessionStore";
import useUserOnboardingStore from "../userOnboardingStore";

export const userOnboardingStepsStatus = {
  COMPLETED: "completed",
  PENDING: "pending",
  ERROR: "error",
};

export const routesWithUserOnboardingBanners = [
  routes.become_a_creator,
  routes.profile,
  routes.offerings,
  routes.settings_payments,
];

export function getUserOnboardingSteps() {
  const userOnboardingData = useUserOnboardingStore.getState().data;
  const userData = useSessionStore.getState().userData;

  function getStatusOfUserProfileStep() {
    const { full_avatar, name, username, bio, mobile, email } = userData;

    const steps = {
      avatar: full_avatar,
      name,
      username,
      mobile,
      email,
    };

    const isStepCompleted = Object.values(steps).every((value) => value);

    return {
      status: isStepCompleted
        ? userOnboardingStepsStatus.COMPLETED
        : userOnboardingStepsStatus.PENDING,
    };
  }

  function getStatusOfCreatorFormStep() {
    const { isCreator } = userData;

    const steps = {
      isCreator,
    };

    const isStepCompleted = Object.values(steps).every((value) => value);

    return {
      status: isStepCompleted
        ? userOnboardingStepsStatus.COMPLETED
        : userOnboardingStepsStatus.PENDING,
    };
  }

  function getStatusOfSetupPaymentsStep() {
    const { has_default_bank_account, is_payment_account_connected } = userData;

    const steps = {
      has_default_bank_account,
      is_payment_account_connected,
    };

    const isStepCompleted =
      has_default_bank_account || is_payment_account_connected;

    return {
      status: isStepCompleted
        ? userOnboardingStepsStatus.COMPLETED
        : userOnboardingStepsStatus.PENDING,
    };
  }

  function getStatusOfVerificationStep() {
    const { onboarding_status, is_onboarding_complete } = userOnboardingData;

    const steps = {
      onboarding_status, // PENDING, COMPLETED, REJECTED, PERMANENTLY_REJECTED
      is_onboarding_complete,
    };

    const isStepPending =
      onboarding_status === "PENDING" || onboarding_status === "COMPLETED";
    const isStepCompleted = is_onboarding_complete || false;

    return {
      status: isStepCompleted
        ? userOnboardingStepsStatus.COMPLETED
        : isStepPending
        ? userOnboardingStepsStatus.PENDING
        : userOnboardingStepsStatus.ERROR,
    };
  }

  function getSteps() {
    const steps = [
      {
        id: "user_profile",
        label: "Setup your profile",
        description: "For your basic creator profile",
        status: getStatusOfUserProfileStep().status,
        emoji: "👨‍🦲",
        route: routes.profile.route,
      },
      {
        id: "creator_form",
        label: "Submit creator application",
        description: "Fill in the form and unlock tools to earn from audience",
        status: getStatusOfCreatorFormStep().status,
        emoji: "👤",
        route: routes.become_a_creator.route,
      },
      {
        id: "setup_payments",
        label: "Setup payments",
        description: "For receiving payouts of your earnings",
        status: getStatusOfSetupPaymentsStep().status,
        emoji: "🏦",
        route: routes.settings_payments.route,
      },
      {
        id: "verification_status",
        label: "Verification status",
        description: "Get a secured profile badge after becoming a creator",
        status: getStatusOfVerificationStep().status,
        emoji: "🚀",
        route: routes.home.route,
      },
    ];

    return steps;
  }

  return userOnboardingData ? getSteps() : null;
}
