import create from "zustand";
import { devtools } from "zustand/middleware";


const userOnboardingStore = (set) => ({
  isLoading: false,
  data: null,
  setData: (data) => {
    set({ data });
  },
  setIsLoading: (isLoading) => {
    set({ isLoading });
  }
});

const useUserOnboardingStore = create(devtools(userOnboardingStore));

export default useUserOnboardingStore;
