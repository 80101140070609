import Link from "next/link";
import cls from "classnames";
import routes from "routes";
import withActiveTab from "hooks/withActiveTab";

import useSessionStore from "store/sessionStore";

const homeTabs = [
  routes.home,
  // routes.superlink, // TODO: Bring this back in product
];

const monetiseTabs = [routes.offerings];

const growthTabs = [routes.transactions];

const adminTabs = [routes.profile, routes.settings];

export default function SideBar({ className }) {
  const userData = useSessionStore((state) => state.userData);
  const isTransactionsTableEnabed =
    userData?.enable_transactions_table ?? false;

  return (
    <div
      className={cls(
        "text-medium h-[calc(100%-60px)] text-base fixed w-56 top-[60px] bg-[#F9FAFA] py-8 overflow-scroll",
        className
      )}
    >
      <div className="flex flex-col gap-4">
        {/*<ProfileTab />*/}
        <div>
          {homeTabs.map((tab, index) => (
            <Tab key={index} tab={tab} />
          ))}
        </div>
        <div>
          <span className="flex text-[#898989] text-sm w-full px-5 py-2">
            Monetise
          </span>
          {monetiseTabs.map((tab, index) => (
            <Tab key={index} tab={tab} />
          ))}
        </div>
        {isTransactionsTableEnabed && (
          <div>
            <span className="flex text-[#898989] text-sm w-full px-5 py-2">
              Payments
            </span>
            {growthTabs.map((tab, index) => (
              <Tab key={index} tab={tab} />
            ))}
          </div>
        )}
        <div>
          <span className="flex text-[#898989] text-sm w-full px-5 py-2">
            Configure
          </span>
          {adminTabs.map((tab, index) => (
            <Tab key={index} tab={tab} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ProfileTab() {
  const userData = useSessionStore((state) => state.userData);

  function openProfileModal() {
    // console.log("openProfileModal");
  }

  return (
    <div className="flex flex-row mb-5 py-3">
      <img
        className="w-[60px] h-[60px] rounded-[50%] mr-3"
        src={userData.avatar}
      />
      <div className="flex flex-col justify-center font-semibold">
        <span className="">{userData.name}</span>
        <button
          className="text-[#959CA4] hover:underline cursor:pointer text-sm"
          onClick={openProfileModal}
        >
          Edit profile
        </button>
      </div>
    </div>
  );
}

const Tab = withActiveTab(({ tab, isActive }) => {
  const { label, icon, selected_icon, route } = tab;

  let CurrentIcon = null;

  if (isActive) {
    CurrentIcon = selected_icon ?? icon;
  } else {
    CurrentIcon = icon;
  }

  return (
    <Link
      href={route}
      className={cls(
        "flex flex-row items-center w-full px-5 py-3",
        isActive
          ? "bg-[#E9E9EB] text-[#3945DC]"
          : "hover:text-[#3945DC] text-[#16191B]",
        isActive ? "font-bold" : "hover:font-semibold"
      )}
    >
      <CurrentIcon className="w-5 h-5" />
      <span className={cls("ml-3 text-base")}>{label}</span>
    </Link>
  );
});
