import cls from "classnames";
import { useRouter } from "next/router";

import { routesWithFullLayout } from "routes";
import NavBar from "components/NavBar";
import SideBar from "./SideBar";
import MainContent from "./MainContent";

import { routesWithUserOnboardingBanners } from "store/userOnboardingStore/helpers/userOnboarding";
import MobileOverlay from "./MobileOverlay";

export default function DashboardLayout({ children }) {
  const router = useRouter();
  // console.log("router.....", router);

  // const username = profileData?.user_details?.username;
  const isFullLayout =
    routesWithFullLayout.findIndex(
      (r) => router.asPath.indexOf(r.route) === 0
    ) > -1;

  const showUserOnboarding =
    routesWithUserOnboardingBanners.findIndex(
      (r) => router.asPath.indexOf(r.route) === 0
    ) > -1;

  return (
    <>
      <MobileOverlay />
      <div className="min-h-screen flex flex-col">
        <NavBar useDropdownAuth={true} />

        <Layout className={cls("flex flex-row")} isFullLayout={isFullLayout}>
          {!isFullLayout && <SideBar className={"z-[1]"} />}

          <MainContent
            isFullLayout={isFullLayout}
            showUserOnboarding={showUserOnboarding}
          >
            {children}
          </MainContent>
        </Layout>
      </div>
    </>
  );
}

function Layout({ className, children, isFullLayout }) {
  return (
    <div className={cls(className, "w-full self-center px-0 flex-1")}>
      {children}
    </div>
  );
}
