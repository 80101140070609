import httpf from "utils/api/frontend/httpf";
import { QOOHOO_WEBSITE_DOMAIN } from "constants/env";

export default function getAuthMe() {
  return httpf.get(`${QOOHOO_WEBSITE_DOMAIN}/api/auth/me`, {
    withCredentials: true,
    headers: {
      Authorization: "bearer 1234", // Authorization header is not required by api as we use cookie for auth, this is only for skipping cache
    },
  });
}