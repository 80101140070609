export default function MobileOverlay() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 text-center text-base text-white sm:hidden block blur-overlay">
      <div className="m-auto flex flex-col items-center justify-center gap-3 w-2/3 h-full">
        <UserImage />
        {"Please open in desktop for the best experience"}
      </div>
    </div>
  );
}

function UserImage() {
  return (
    <svg
      viewBox="0 0 36 36"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      className="h-[40px]"
    >
      <mask
        id="mask__beam"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <rect width="36" height="36" fill="#FFFFFF" rx="72"></rect>
      </mask>
      <g mask="url(#mask__beam)">
        <rect width="36" height="36" fill="#ffe4aa"></rect>
        <rect
          x="0"
          y="0"
          width="36"
          height="36"
          transform="translate(-3 7) rotate(67 18 18) scale(1.1)"
          fill="#e2869b"
          rx="36"
        ></rect>
        <g transform="translate(-3 3.5) rotate(7 18 18)">
          <path d="M13,20 a1,0.75 0 0,0 10,0" fill="#000000"></path>
          <rect
            x="12"
            y="14"
            width="1.5"
            height="2"
            rx="1"
            stroke="none"
            fill="#000000"
          ></rect>
          <rect
            x="22"
            y="14"
            width="1.5"
            height="2"
            rx="1"
            stroke="none"
            fill="#000000"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
