import {IS_PROD, WEBSITE_DOMAIN} from './src/constants/env';

const defaultSEOConfig = {
  siteName: WEBSITE_DOMAIN,
  title: "qoohoo | dashboard",
  description:
    "Dashboard to manage, run and analyse your community from a single place at qoohoo.",
  metaDescription:
    "Dashboard to manage, run and analyse your community from a single place at qoohoo.",
  image: `${WEBSITE_DOMAIN}/assets/app-logo.svg`,
  bannerImage: `${WEBSITE_DOMAIN}/assets/images/OG.png`,
};

export default function getSEOConfig() {
  const seoConfigData = defaultSEOConfig;

  const seoConfigTemplate = {
    title: seoConfigData.title,
    description: seoConfigData.metaDescription,
    openGraph: {
      type: "website",
      url: WEBSITE_DOMAIN,
      title: seoConfigData.title,
      description: seoConfigData.description,
      site_name: seoConfigData.siteName,
      images: [
        {
          url: seoConfigData.bannerImage,
          width: 1810,
          height: 1006,
          alt: "qoohoo banner image",
          type: "image/png",
        },
      ],
    },
    twitter: {
      handle: "@qoohooapp",
      cardType: "summary_large_image",
      site: "@qoohooapp",
    },
    dangerouslyDisableGooglebot: !IS_PROD,
    dangerouslySetAllPagesToNoIndex: !IS_PROD,
    dangerouslySetAllPagesToNoFollow: !IS_PROD,
  };

  return seoConfigTemplate;
}
